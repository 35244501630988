* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css?family=Amatic+SC');

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

body {
  overflow: hidden;
  background-color: #000000;
  font-family: 'Roboto', sans-serif;
  /* min-height: 100vh;
    min-height: -webkit-fill-available; */
}

.overlay {
  opacity: 1;
  background-color: rgb(0, 0, 0);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  pointer-events: none;
  transition: opacity 1s;
}

.overlay.fade {
  opacity: 0;
}

h1 {
  position: absolute;
  margin: auto;
  bottom: -5vh;
  width: 30vh;
  min-width: 30vmax;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 2vmax;
  color: #ffffff;
  opacity: 0.75;
  animation: pulse 2.5s linear infinite;
}

.start {
  position: fixed;
  top: calc((100vh - 100px) / 2);
  right: calc((100vw - 200px) / 2);
  width: 200px;
  height: 100px;
  border: none;
  color: white;
  background-color: transparent;
  cursor: pointer;
  font-size: 3.5rem;
  font-family: 'Roboto', sans-serif;
  opacity: 0;
  transition: opacity 0.5s;
  display: none;
}
.menu,
.appLink {
  position: fixed;
  font-size: 18px;
  color: white;
  top: 10px;
  right: 15px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 1rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: none;
}
.menu:hover, .menu:focus, 
.appLink:hover, .appLink:focus {
  background-color: rgba(255, 255, 255,  0.25);
}
.menu {
  right: auto;
  left: 10px
}
.backToDefault {
  position: fixed;
  font-size: 25px;
  color: white;
  top: 10px;
  left: 10px;
  padding: 1rem;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 15px;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s 0.4s;
}

.start.fadeIn {
  opacity: 1;
}
.menu.fadeIn,
.appLink.fadeIn,
.backToDefault.fadeIn {
  pointer-events: all ;
  opacity: 1;
}
.start.fadeOut {
  opacity: 0;
}

@media (hover: hover) and (pointer: fine) {
  .start:hover {
    color: #ff0000;
  }
}

#cooking {
  position: relative;
  margin: auto;
  top: calc((100vh - 55vmax) / 2);
  width: 30vh;
  min-width: 30vmax;
  height: 30vh;
  min-height: 30vmax;
  overflow: visible;
  opacity: 1;
  transition: opacity 1s;
}

#cooking.fade {
  opacity: 0;
}

/* @media (orientation: landscape) {
    #cooking {
      top: 10vh;
    }
  }
  
  @media (orientation: portrait) {
    #cooking {
      top: 15vh;
    }
  } */

#cooking #area {
  position: absolute;
  bottom: 0;
  right: 2.5vw;
  width: 50%;
  height: 50%;
  background-color: transparent;
  transform-origin: 15% 60%;
  animation: flip 2.1s ease-in-out infinite;
}

#cooking #area #sides {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 15% 60%;
  animation: switchSide 2.1s ease-in-out infinite;
}

#cooking #area #sides #handle {
  position: absolute;
  bottom: 18%;
  right: 80%;
  width: 35%;
  height: 20%;
  background-color: transparent;
  border-top: 1vh solid #ffffff;
  border-left: 1vh solid transparent;
  border-radius: 100%;
  transform: rotate(20deg) rotateX(0deg) scale(1.3, 0.9);
}
#cooking #area #sides #pan {
  position: absolute;
  bottom: 10%;
  right: 30%;
  width: 50%;
  height: 20%;
  background-color: #ffffff;
  border-radius: 0 0 1.4em 1.4em;
  transform-origin: -15% 0;
}

#cooking #area #pancake {
  position: absolute;
  top: 24%;
  width: 100%;
  height: 100%;
  transform: rotateX(85deg);
  animation: jump 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry {
  position: absolute;
  bottom: 26%;
  right: 40%;
  width: 20%;
  height: 30%;
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 #ffffff;
  border-radius: 100%;
  transform-origin: -140% 0;
  animation: fly 2.1s ease-in-out infinite;
}

/* #cooking #area #pancake #pastry {
    position: absolute;
    bottom: 26%;
    right: 37%;
    width: 40%;
    height: 45%;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 #ffffff;
    border-radius: 100%;
    transform-origin: -20% 0;
    animation: fly 2.1s ease-in-out infinite;
} */

#cooking #area #pancake #pastry2 {
  position: absolute;
  bottom: 26%;
  right: 60%;
  width: 15%;
  height: 20%;
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 #ffffff;
  border-radius: 100%;
  transform-origin: -80% 0;
  animation: fly 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry3 {
  position: absolute;
  bottom: 26%;
  right: 70%;
  width: 10%;
  height: 25%;
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 #ffffff;
  border-radius: 100%;
  transform-origin: -160% 0;
  animation: fly 2.1s ease-in-out infinite;
}

#cooking .bubble {
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0.25vh #ffffff;
  opacity: 0;
}

#cooking .bubble:nth-child(1) {
  margin-top: -9.5vh;
  left: 58%;
  width: 2.5vh;
  height: 2.5vh;
  background-color: #ffffff;
  animation: bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
}

#cooking .bubble:nth-child(2) {
  margin-top: -10vh;
  left: 52%;
  width: 2vh;
  height: 2vh;
  background-color: #ffffff;
  animation: bubble 2s ease-in-out 0.35s infinite;
}

#cooking .bubble:nth-child(3) {
  margin-top: -8.8vh;
  left: 50%;
  width: 1.5vh;
  height: 1.5vh;
  background-color: #ffffff;
  animation: bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(4) {
  margin-top: -9.7vh;
  left: 56%;
  width: 1.2vh;
  height: 1.2vh;
  background-color: #ffffff;
  animation: bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(5) {
  margin-top: -9.7vh;
  left: 63%;
  width: 1.1vh;
  height: 1.1vh;
  background-color: #ffffff;
  animation: bubble 1.6s ease-in-out 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 0.25;
  }
  50% {
    transform: scale(1.2, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0.25;
  }
}

@keyframes flip {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotate(-27deg);
  }

  30%,
  50% {
    transform: rotate(0deg);
  }

  55% {
    transform: rotate(27deg);
  }

  83.3% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes switchSide {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes jump {
  0% {
    top: 24;
    transform: rotateX(85deg);
  }
  25% {
    top: 10%;
    transform: rotateX(0deg);
  }
  50% {
    top: 30%;
    transform: rotateX(85deg);
  }
  75% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(85deg);
  }
}

@keyframes fly {
  0% {
    bottom: 26%;
    transform: rotate(0deg);
  }
  10% {
    bottom: 40%;
  }
  50% {
    bottom: 26%;
    transform: rotate(-190deg);
  }
  80% {
    bottom: 40%;
  }
  100% {
    bottom: 26%;
    transform: rotate(0deg);
  }
}

@keyframes bubble {
  0% {
    transform: scale(0.15, 0.15);
    top: 80%;
    opacity: 0;
  }
  50% {
    transform: scale(1.1, 1.1);
    opacity: 0.4;
  }
  100% {
    transform: scale(0.33, 0.33);
    top: 60%;
    opacity: 0;
  }
}
.header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #cf142b;
}
.back {
  font-size: 30px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.back:hover {
  color: rgb(209, 209, 209);
}
/* .logo{
    margin: 0 40px;
} */
.logo img {
  width: 160px;
}
.flags {
  display: flex;
  gap: 10px;
}
.flags button {
  border-radius: 50%;
  border: none;
  display: flex;
  cursor: pointer;
}
.flags button img {
  width: 40px;
}

.grButton:hover,
.ukButton:hover {
  transform: translateY(-5px);
}

.menuList {
  padding-top: 100px;
  background-color: black;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  /* flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.menuList.block {
  display: block;
}
.menuBlock {
  width: 100%;
}
.menuCategory {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  border-bottom: 2px solid white;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.menuCategory h2 {
  order: 2;
  align-self: center;
}
.menuCategory span svg {
  width: 40px;
  height: 40px;
  fill: white;
  line-height: 1;
  order: 1;
}

.greekMenu {
  display: none;
  margin-top: 40px;
  padding: 20px;
  flex-direction: column;
}
.ukMenu {
  margin-top: 40px;
  display: none;
  padding: 20px;
  flex-direction: column;
}

.greekMenu.active {
  display: flex;
}
.greekInfo,
.ukInfo {
  font-size: 18px;
  color: rgb(206, 206, 206);
  display: none;
}
.greekInfo.active {
  display: block;
}
.ukMenu.active {
  display: flex;
}
.ukInfo.active {
  display: block;
}
.menuItems li {
  list-style: none;
  max-width: 90%;
  margin-bottom: 10px;
  font-size: 20px;
}
.menuInfoDescr {
  font-size: 20px;
  margin-bottom: 10px;
  color: rgb(179, 179, 179);
}
li.burgerName {
  font-size: 25px;
  margin-top: 15px;
}
.withPrice + .withPrice {
  margin-top: 3px;
}
p.burgerInfo {
  color: rgb(179, 179, 179);
  font-size: 16px;
}
.menuItems div {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 750px) {
  .logo img {
    width: 200px;
  }
  .flags button img {
    width: 50px;
  }
  .back {
    font-size: 30px;
  }
  .menuBlock {
    width: 45%;
  }
  .backToDefault {
    font-size: 30px;
  }
  .menu,
  .appLink {
    font-size: 24px;
  }
  .greekMenu,
  .ukMenu {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5%;
    margin-top: 100px;
  }
}
@media (min-width: 950px) {
  .menu {
    left:auto;
    right: 265px;
  }
}
